<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<booking-modals :modelName="modelName"></booking-modals>
	</div>
</template>

<script>
import bookingActions from './BookingActions';
import bookingModals from './BookingModals.vue';

export default {
	name: 'booking-grid',
	components: {
		'booking-modals': bookingModals
	},
	data() {
		return {
			modelName: 'booking',
			actions: bookingActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
