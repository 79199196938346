<template>
	<div>
		<pui-modal-dialog-form
			:titleText="$t('actions.booking.massiveBookings')"
			:modelName="modelName"
			:dialogName="massiveBookings"
			:onShow="onShowMassiveBookings"
			:onOk="onOkMassiveBookings"
			:showDialogProp="showDialogMassiveBookings"
			:overflow="false"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="2">
						<pui-select
							:id="`port-booking`"
							:attach="`port-booking`"
							:label="$t('form.booking.port')"
							required
							toplabel
							clearable
							modelName="port"
							v-model="model"
							reactive
							:itemsToSelect="[{ id: model.port }]"
							:modelFormMapping="{ id: 'port' }"
							itemValue="id"
							itemText="portname"
							:fixedFilter="filterByUserPortsAndAuthority"
							@input="berthFilter()"
						></pui-select>
					</v-col>
					<v-col cols="6">
						<pui-select
							:id="`berth-booking`"
							:attach="`berth-booking`"
							:label="$t('form.booking.berth')"
							required
							toplabel
							clearable
							modelName="berth"
							v-model="model"
							reactive
							:itemsToSelect="[{ id: model.berth }]"
							:modelFormMapping="{ id: 'berth' }"
							itemValue="id"
							itemText="name"
							:order="{ name: 'asc' }"
							:fixedFilter="berthFixedFilter"
							@input="bollardFilter()"
						></pui-select>
					</v-col>
					<v-col cols="2">
						<pui-select
							:id="`bollardiniid-${modelName}`"
							:attach="`bollardiniid-${modelName}`"
							:label="$t('form.booking.bollardiniid')"
							toplabel
							modelName="bollard"
							v-model="model"
							reactive
							:itemsToSelect="[{ id: model.bollardiniid }]"
							:modelFormMapping="{ id: 'bollardiniid' }"
							itemValue="id"
							itemText="bolcode"
							:order="{ orderby: 'asc' }"
							:fixedFilter="bollardFixedFilter"
						></pui-select>
					</v-col>
					<v-col cols="2">
						<pui-select
							:id="`bollardendid-${modelName}`"
							:attach="`bollardendid-${modelName}`"
							:label="$t('form.booking.bollardendid')"
							toplabel
							clearable
							modelName="bollard"
							v-model="model"
							reactive
							:itemsToSelect="[{ id: model.bollardendid }]"
							:modelFormMapping="{ id: 'bollardendid' }"
							itemValue="id"
							itemText="bolcode"
							:order="{ orderby: 'asc' }"
							:fixedFilter="bollardFixedFilter"
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="5">
						<pui-select
							:id="`companyid-booking`"
							:attach="`companyid-booking`"
							:label="$t('form.booking.companyid')"
							required
							toplabel
							clearable
							v-model="model"
							modelName="company"
							:modelFormMapping="{ id: 'companyid' }"
							:itemsToSelect="[{ id: model.companyid }]"
							:itemValue="['id']"
							:itemText="(item) => `${item.name}`"
							:order="{ name: 'asc' }"
							:fixedFilter="filterCompany"
						></pui-select>
					</v-col>
					<v-col cols="3">
						<pui-select
							:id="`fromportcode-${modelName}`"
							:attach="`fromportcode-${modelName}`"
							:label="$t('form.booking.fromportcode')"
							toplabel
							clearable
							required
							v-model="model"
							modelName="worldport"
							:modelFormMapping="{ code: 'fromportcode' }"
							:itemsToSelect="[{ code: model.fromportcode }]"
							:itemValue="['code']"
							:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
							reactive
						></pui-select>
					</v-col>
					<v-col cols="3">
						<pui-select
							:id="`toportcode-${modelName}`"
							:attach="`toportcode-${modelName}`"
							:label="$t('form.booking.toportcode')"
							toplabel
							clearable
							required
							v-model="model"
							modelName="worldport"
							:modelFormMapping="{ code: 'toportcode' }"
							:itemsToSelect="[{ code: model.toportcode }]"
							:itemValue="['code']"
							:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
							reactive
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="5">
						<pui-select
							:id="`shiptype-booking`"
							:attach="`shiptype-booking`"
							:label="$t('form.booking.shiptype')"
							toplabel
							clearable
							modelName="shiptypepa"
							v-model="model"
							reactive
							required
							:itemsToSelect="[{ code: model.shiptype }]"
							:modelFormMapping="{ code: 'shiptype' }"
							itemValue="code"
							itemText="description"
							:order="{ description: 'asc' }"
							:fixedFilter="filterByPortAuthorityUser"
							@input="shipTypeFilter()"
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="8">
						<pui-select
							:id="`ship-booking`"
							:attach="`ship-booking`"
							:label="$t('form.booking.ship')"
							toplabel
							clearable
							modelName="ship"
							v-model="model"
							reactive
							:itemsToSelect="[{ id: model.ship }]"
							:modelFormMapping="{ id: 'ship' }"
							:itemValue="['id']"
							:itemText="(item) => `${item.imo} - ${item.name}`"
							:order="{ name: 'asc' }"
							:fixedFilter="shipVersionFilter"
							@input="getShipData()"
						></pui-select>
					</v-col>
					<v-col cols="2">
						<pui-number-field
							:id="`length-booking`"
							v-model="model.length"
							:label="$t('form.booking.length')"
							toplabel
							integer
							required
							min="0"
							max="99999"
						></pui-number-field>
					</v-col>
					<v-col cols="2">
						<pui-number-field
							:id="`beam-booking`"
							v-model="model.beam"
							:label="$t('form.booking.beam')"
							toplabel
							integer
							required
							min="0"
							max="99999"
						></pui-number-field>
					</v-col>
				</v-row>
				<pui-field-set :title="$t('modal.booking.fieldset.chooseDates')">
					<v-row dense>
						<v-col cols="5">
							<pui-date-field
								:id="`eta-${modelName}`"
								v-model="model.eta"
								:label="$t('form.booking.eta')"
								required
								toplabel
								clearable
								:max="model.etd ? model.etd : null"
							></pui-date-field>
						</v-col>
						<v-col cols="5">
							<pui-date-field
								:id="`enddate-${modelName}`"
								v-model="model.etd"
								:label="$t('form.booking.etd')"
								required
								toplabel
								clearable
								:min="model.eta ? model.eta : null"
							></pui-date-field>
						</v-col>
					</v-row>
					<v-row dense v-for="(row, index) in rows" :key="index">
						<v-col cols="3">
							<v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="150px">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-on="on"
										v-bind="attrs"
										ref="booking.enddate"
										v-model="row.starttime"
										append-icon="fa-clock"
										clearable
										solo
										outlined
										flat
										class="ml-1"
										:placeholder="$t('modal.booking.starttime')"
									></v-text-field>
								</template>
								<v-time-picker v-model="row.starttime" format="24hr" color="var(--primarycolor)" :max="row.endtime"></v-time-picker>
							</v-menu>
						</v-col>
						<v-col cols="3">
							<v-menu :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="150px">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-on="on"
										v-bind="attrs"
										ref="booking.starttime"
										v-model="row.endtime"
										append-icon="fa-clock"
										clearable
										solo
										outlined
										flat
										class="ml-1"
										:placeholder="$t('modal.booking.endtime')"
									></v-text-field>
								</template>
								<v-time-picker v-model="row.endtime" format="24hr" color="var(--primarycolor)" :min="row.starttime"></v-time-picker>
							</v-menu>
						</v-col>
						<v-col cols="4">
							<v-select
								v-model="row.days"
								:items="allWeekDays"
								:itemsToSelect="row.days"
								:itemValue="['id']"
								:itemText="['value']"
								multiple
								solo
								flat
								hide-details
								reactive
								:placeholder="$t('modal.booking.days')"
							></v-select>
						</v-col>
						<v-col cols="2">
							<v-btn icon @click="addRow">
								<v-icon>fak fa-solid-add</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</pui-field-set>
				<v-row dense> </v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:titleText="$t('actions.booking.linkBookingStop')"
			:modelName="modelName"
			:dialogName="linkBookingStop"
			:onShow="onShowLinkBookingStop"
			:onOk="onOkLinkBookingStop"
			:showDialogProp="showDialogLinkBookingStop"
			:overflow="false"
			:widthDialog="800"
		>
			<template slot="message">
				<pui-select
					:id="`stop-${modelName}`"
					:attach="`stop-${modelName}`"
					:label="$t('actions.booking.stop')"
					toplabel
					clearable
					required
					v-model="model"
					modelName="stop"
					:itemsToSelect="[{ id: this.model.stop }]"
					:modelFormMapping="{ id: 'stop' }"
					itemValue="id"
					:itemText="(item) => getStopItemText(item)"
					:order="{ eta: 'asc' }"
					:fixedFilter="filterByStopStatus"
					reactive
				></pui-select>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'booking-modals',
	data() {
		return {
			massiveBookings: 'massiveBookings',
			showDialogMassiveBookings: true,
			model: {},
			bollardFixedFilter: null,
			berthFixedFilter: null,
			shipVersionFilter: null,
			rows: [
				{
					starttime: '',
					endtime: '',
					days: []
				}
			],
			allWeekDays: [
				{ id: 1, value: this.$t(`common.weekday.1`) },
				{ id: 2, value: this.$t(`common.weekday.2`) },
				{ id: 3, value: this.$t(`common.weekday.3`) },
				{ id: 4, value: this.$t(`common.weekday.4`) },
				{ id: 5, value: this.$t(`common.weekday.5`) },
				{ id: 6, value: this.$t(`common.weekday.6`) },
				{ id: 7, value: this.$t(`common.weekday.0`) }
			],
			linkBookingStop: 'linkBookingStop',
			showDialogLinkBookingStop: true,
			statusExcluded: 'FI',
			acceptedStopStatus: null
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onShowMassiveBookings(modalData) {
			this.rows = [
				{
					starttime: '',
					endtime: '',
					days: []
				}
			];
			this.model = modalData;
		},
		onOkMassiveBookings(modalData) {
			modalData.portauthority = window.localStorage.getItem('workingPortAuthorityId');

			this.removeEmptyRows();

			this.rows.forEach((row) => {
				if (row.starttime) {
					row.starttime = this.formatHours(row.starttime);
				}
				if (row.endtime) {
					row.endtime = this.formatHours(row.endtime);
				}
				if (Array.isArray(row.days)) {
					row.days = row.days.join(',');
				}
			});

			modalData.timeslots = this.rows;

			return new Promise(async (resolve) => {
				await this.insertBookings(modalData);
				resolve(true);
			});
		},
		insertBookings(modalData) {
			this.$puiRequests.postRequest(
				'/booking/insertBookings',
				modalData,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogMassiveBookings = false;
					this.rows = [
						{
							starttime: '',
							endtime: '',
							days: []
						}
					];
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogMassiveBookings = false;
					this.rows = [
						{
							starttime: '',
							endtime: '',
							days: []
						}
					];
				}
			);
		},
		formatHours(time) {
			const currentDate = new Date();
			const [hours, minutes] = time.split(':');
			currentDate.setHours(hours, minutes, 0, 0);
			const formattedDate = currentDate.toISOString();
			return formattedDate;
		},
		bollardFilter() {
			if (this.model.berth) {
				this.bollardFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'berthid', op: 'eq', data: this.model.berth }]
				};
			} else {
				this.bollardFixedFilter = null;
			}
		},
		berthFilter() {
			if (this.model.port) {
				this.berthFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'type', op: 'eq', data: 1 },
						{ field: 'portid', op: 'eq', data: this.model.port }
					]
				};
			} else {
				this.berthFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'type', op: 'eq', data: 1 }]
				};
			}
		},
		addRow() {
			this.rows.push({
				starttime: '',
				endtime: '',
				days: []
			});
		},
		removeEmptyRows() {
			this.rows = this.rows.filter((row) => row.starttime !== '' && row.endtime !== '');
		},
		shipTypeFilter() {
			const portAuthorityId = window.localStorage.getItem('workingPortAuthorityId');
			if (this.model.shiptype) {
				this.getShipTypeByCodeAndPa(this.model.shiptype, portAuthorityId);
			} else {
				this.shipVersionFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'next_version_id', op: 'nu', data: null },
						this.ownlistships
							? { field: 'port_authority_id', op: 'eq', data: portAuthorityId }
							: { field: 'port_authority_id', op: 'nu', data: null }
					]
				};
			}
		},
		async getShipTypeByCodeAndPa(shipTypeCode, portAuthorityId) {
			try {
				const response = await this.$puiRequests.postRequest('/puisearch', {
					model: 'shiptypenswpa',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{ field: 'shiptypepa', op: 'eq', data: shipTypeCode },
							{ field: 'portauthorityid', op: 'eq', data: portAuthorityId }
						]
					}
				});

				if (response && response.data) {
					const concatenatedShipTypes = response.data.data.map((item) => item.shiptypensw).join(',');

					this.model.shiptypensw = concatenatedShipTypes;
					this.shipVersionFilter = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'type', op: 'in', data: this.model.shiptypensw },
							{ field: 'next_version_id', op: 'nu', data: null },
							this.ownlistships
								? { field: 'port_authority_id', op: 'eq', data: portAuthorityId }
								: { field: 'port_authority_id', op: 'nu', data: null }
						]
					};
				}
			} catch (error) {
				this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
			}
		},
		getShipData() {
			if (this.model.ship) {
				const opts = {
					model: 'ship',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'id',
								op: 'eq',
								data: this.model.ship
							}
						]
					}
				};

				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					if (response.data && response.data.data[0]) {
						this.model.length = response.data.data[0].length;
						this.model.beam = response.data.data[0].beam;
						this.model.shiptype = response.data.data[0].shiptypepa;
					}
				});
			}
		},
		onShowLinkBookingStop(modalData) {
			this.model = modalData;
			this.getStopStatusListBeforeStatus();
		},
		onOkLinkBookingStop() {
			const params = {
				stop: this.model.stop
			};
			let pk = {
				id: this.model.id
			};

			return new Promise(async (resolve) => {
				await this.doLinkBookingStop(params, pk);
				resolve(true);
			});
		},
		async doLinkBookingStop(params, pk) {
			if (params) {
				const url = '/booking/linkBookingStop';

				this.$puiRequests.patchRequest(
					url,
					params,
					() => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
						this.showDialogLinkBookingStop = false;
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);
						this.showDialogLinkBookingStop = false;
					},
					pk
				);
			}
		},
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			return `${item.portcallnumber} - ${item.num} - ${this.$dateTimeUtils.getLocalFormattedDate(startdate, 'DD/MM/YYYY HH:mm')} - ${
				item.location
			}`;
		},
		getStopStatusListBeforeStatus() {
			let status = this.$store.getters.stopsStatusValues;
			const statusArray = Object.keys(status).map((code) => ({
				code,
				...status[code]
			}));
			let order = statusArray.filter((status) => status.stopstatuscode == this.statusExcluded)[0].orderby;
			let filteredStatus = statusArray.filter((status) => status.orderby < order);
			this.acceptedStopStatus = filteredStatus.map((status) => status.stopstatuscode);
		}
	},
	computed: {
		filterByUserPortsAndAuthority() {
			const userPorts = this.$store.getters.getPortsIds;
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'id', op: 'in', data: userPorts },
					{ field: 'portauthorityid', op: 'eq', data: this.$store.getters.getPortAuthorityId }
				]
			};
		},
		filterCompany() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'portauthorityid', op: 'eq', data: this.$store.getters.getPortAuthorityId },
					{ field: 'companytypeid', op: 'eq', data: 5 }
				]
			};
		},
		filterByPortAuthorityUser() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId }]
			};
		},
		filterByStopStatus() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'statusid', op: 'in', data: this.acceptedStopStatus }]
			};
		}
	},
	created() {},
	mounted() {}
};
</script>
