const massiveBookings = {
	id: 'massiveBookings',
	selectionType: 'general',
	label: 'actions.booking.massiveBookings',
	functionality: 'ACTION_ADD_MASSIVE_BOOKINGS',
	checkAvailability: function () {
		return true;
	},
	runAction: function () {
		let row = {};
		row.model = this.modelName;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-massiveBookings-' + this.modelName + '-show', row);
	}
};

const linkBookingStop = {
	id: 'linkBookingStop',
	selectionType: 'single',
	label: 'actions.booking.linkBookingStop',
	functionality: 'ACTION_LINK_BOOKING_STOP',
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-linkBookingStop-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [massiveBookings, linkBookingStop],
	formactions: []
};
